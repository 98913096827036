import { useCallback, useEffect } from 'react';
import FAQ_DATA from '@lib/constants/FAQ_DATA';
import { useMobileScreen } from '@lib/view/hooks/screens';
import AOS from 'aos';
import React from 'react';
import { Element, scroller } from 'react-scroll';
import FeedbackSection from '@home/feedback';
import FrequentlyAskQuestion from '@home/frequently-ask-quesiton';
import GrowthSection from '@home/growth';
import MoreDetailItem from '@home/more-details-item';
import SubcriberSection from '@home/subscriber';
import TrackOrderSection from '@home/track-order';
import PolicyAndReview from '@home/policy-and-review';
import Layout from '@lib/view/components/layout';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

const HomePage: NextPage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);
    const { push } = useRouter();
    const onScroll = useCallback(() => {
        // event.preventDefault();
        scroller.scrollTo('input-element', {
            offset: 0,
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }, []);

    const handleClickButton = () => {
        push('/create-order');
    };

    const mobile = useMobileScreen();
    return (
        <Layout footer titleDefault="TubeKick">
            {/* -----  Introduction ----- */}
            <section className="md:pb-0 relative bg-no-repeat bg-cover z-2">
                <div className="hidden xsMax:flex absolute top-0 right-0 left-0 bottom-0 bg-home-tiktok-rp-linepals-header-gradient bg-no-repeat bg-cover bg-tiktok-banner-linepals" />
                <div className="flex xsMax:hidden absolute top-0 right-0 left-0 bottom-0 bg-tiktok-banner-linepals bg-no-repeat bg-cover" />
                <div className="flex w-full items-center text-white min-h-545px md:min-h-750px md:py-25 pt-25 pb-15">
                    <div className="flex items-center w-full mx-5 md:container md:mx-auto">
                        <div
                            className="text-center md:text-left sm:px-4 w-full max-w-150"
                            data-aos="fade-up"
                        >
                            <h1 className="font-Circular-Std font-bold leading-65px md:leading-90px flex md:justify-start justify-center flex-col">
                                <span className="md:text-70px text-4xl text-primary">
                                    We write your
                                </span>
                                <span className="md:text-96px text-60px text-black">
                                    Blog Posts
                                </span>
                            </h1>

                            <Element name="input-element" />
                            <button
                                className=" bg-primary h-15 md:w-251px w-full rounded-lg mt-13 md:mt-10 mb-9 font-Inter font-semibold text-base"
                                onClick={handleClickButton}
                            >
                                Get started today!
                            </button>
                            <PolicyAndReview
                                spacing="md:pt-4 pt-2 mb-10"
                                reviewerSpacing="md:mb-0"
                                fontSizeTitle="text-13px md:text-base whitespace-nowrap md:leading-5 leading-4 font-Inter font-normal md:font-medium text-black"
                                classBoxTitle="flex"
                                spacingChecked="mr-6px"
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* ----- How ViewPals works ----- */}
            <section className="md:container px-5 md:px-35px pt-8 md:pt-110px mx-auto">
                <SubcriberSection
                    onScroll={onScroll}
                    title={mobile ? 'Grow Tiktok' : 'Automate your'}
                    desc="Automating your content production has never been easier. Our new order making process cuts the time significantly and makes it easier than ever for you to order blog posts and content written to your specification. "
                />
                <GrowthSection
                    desc="It doesn’t matter whether you are looking to write an Instagram Post, a Blog post of 200 Characters or a long format, SEO blog Post of 2,000 characters or more. Our Content team got you covered. "
                    onScroll={onScroll}
                    title={mobile ? 'TikTok Growth for' : 'Long & Short'}
                />
            </section>
            {/* ----- Trusted ----- */}
            <section className="h-420px md:h-500px relative mt-16 md:mt-150px  bg-no-repeat bg-cover">
                <FeedbackSection
                    desc={
                        mobile
                            ? 'With Linepals I am able to boost individual TikTok posts or my entire follower base. Works like magic!'
                            : 'With Content Pals I was finally able to automate my copywriting and grow my business with a reliable partner.'
                    }
                    name="-tiktok-rp"
                    userName={mobile ? '- Jenny Walts' : '- Wolfreign'}
                />
            </section>

            {/* ------view and subscriber----- */}
            <section className="md:container px-5 mx-auto flex flex-col md:flex-row justify-between space-y-16 space-x-0 md:space-y-0 md:space-x-20 pt-73px md:pt-124px">
                <MoreDetailItem
                    imageName="tiktok-rp-01"
                    title="Blog Posts"
                    desc="Connect with your audience and increase conversions with our professional copywriting services for blog posts. Our team of experienced writers will craft compelling, engaging content that effectively communicates your message."
                />
                <MoreDetailItem
                    imageName="tiktok-rp-02"
                    title="SEO Blog Posts"
                    desc='"Improve your search engine rankings with our professional SEO blog post writing services. Our team of experts will craft high-quality, engaging content that is optimized for search engines, driving more traffic to your site.'
                />
                <MoreDetailItem
                    imageName="tiktok-rp-03"
                    title="Instagram Captions"
                    desc="Upgrade your Instagram presence with our expert caption writing services. Our team will craft captivating and on-brand captions that will boost engagement and help you stand out. Start today to take your Instagram to the next level."
                />
            </section>
            {/* -------View and subscriber end------- */}

            {/* ----------- */}

            <TrackOrderSection
                onScroll={onScroll}
                name="-tiktok-rp"
                title="An experienced"
                desc="Trust in the expertise of our team of professional copywriters. With years of experience in crafting compelling content for various industries, we know how to create copy that resonates with your target audience and drives conversions"
            />

            {/* --------------- */}

            {/* Frequently Ask Quesiton */}
            <section className="bg-white" id="faq">
                <Element name="faq" />
                <div
                    className="md:container px-5 md:px-0 pt-106px md:pt-48 pb-2 mx-auto md:max-w-750px"
                    data-aos="fade-up"
                >
                    <h2 className="text-center font-bold md:font-medium md:text-28px text-22px font-Circular-Std text-violet-blackcurrant">
                        Frequently Asked Questions
                    </h2>

                    <div className="mt-16 md:mt-20">
                        <div className="mb-73px md:mb-32">
                            <FrequentlyAskQuestion data={FAQ_DATA.homepage} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default HomePage;
